import { createContext } from 'react';

import { useDefinedContext } from '../../../featureSchema';

import { BulkSelectStore } from './store';

export const BulkSelectContext = createContext<BulkSelectStore | undefined>(
  undefined
);

export const useBulkSelectContext = (): BulkSelectStore =>
  useDefinedContext(BulkSelectContext);
