import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { API_DATE_TIME_UTC_FORMAT } from './formats';

export class TimezoneDateUtils {
  static fromLocalToUTC(date: Date | string): string {
    return moment.utc(moment(date).valueOf()).toISOString();
  }

  static fromUTCToLocal(date: Date | string): Date {
    return moment.utc(date).local().toDate();
  }

  static fromUTCToTimezone = (
    date: Date | string,
    timezoneCode: string,
    format: string = API_DATE_TIME_UTC_FORMAT
  ): string => {
    return momentTimezone.utc(date).tz(timezoneCode).format(format);
  };
}
