export const isEmpty = (element: unknown): boolean => {
  if (!element) {
    return true;
  }

  if (Array.isArray(element) || typeof element === 'string') {
    return element.length === 0;
  }

  if (Object.keys(element as object).length === 0) {
    return true;
  }

  return false;
};
